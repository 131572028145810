import React, { useEffect, useMemo, useState } from "react"
import {
    Breadcrumb,
    Layout,
    Modal,
    Space,
    theme,
    Menu,
} from "antd"
import {
    RocketOutlined,
    DatabaseOutlined,
    CloudUploadOutlined,
    SlidersOutlined,
    PlayCircleOutlined,
    CaretDownOutlined,
    MessageOutlined,
} from "@ant-design/icons"
import type { MenuProps } from 'antd';
import Sidebar from "../Sidebar/Sidebar"
import Link from "next/link"
import { isDemo, renameVariablesCapitalizeAll } from "@/lib/helpers/utils"
import { useAppTheme } from "./ThemeContextProvider"
import { useElementSize } from "usehooks-ts"
import { createUseStyles } from "react-jss"
import NoSSRWrapper from "../NoSSRWrapper/NoSSRWrapper"
import { ErrorBoundary } from "react-error-boundary"
import ErrorFallback from "./ErrorFallback"
import { useAppsData } from "@/contexts/app.context"
import { useRouter } from "next/router"
import Image from "next/image"
import { useProfileData } from "@/contexts/profile.context"
import { ThemeProvider } from "react-jss"
import abTesting from "@/media/testing.png"
import singleModel from "@/media/score.png"

const { Content, Footer } = Layout

type StyleProps = {
    themeMode: "dark" | "light"
    footerHeight: number
}

const useStyles = createUseStyles({
    layout: ({ themeMode }: StyleProps) => ({
        display: "flex",
        background: themeMode === "dark" ? "#141414" : "#ffffff",
        height: "100%",
        minHeight: "100vh",
        position: "relative",
    }),
    content: ({ footerHeight }: StyleProps) => ({
        height: `calc(100% - ${footerHeight ?? 0}px)`,
        paddingLeft: "1.5rem",
        paddingRight: "1.5rem",
        // marginLeft: 225,
        // background: "#F7FAFC",
        marginBottom: `calc(${footerHeight ?? 0}px)`,
        flex: 1,
    }),
    breadcrumbContainer: {
        justifyContent: "space-between",
        width: "100%",
    },
    breadcrumb: {
        padding: "24px 0",
    },
    star: ({ themeMode }: StyleProps) => ({
        display: "flex",
        alignItems: "center",
        padding: 0,
        height: 30,
        borderWidth: 2,
        borderColor: themeMode === "dark" ? "#333" : "#dfdfdf",
        "& div:nth-of-type(1)": {
            display: "flex",
            alignItems: "center",
            height: "100%",
            width: "100%",
            gap: 8,
            padding: "0 10px",
            background: themeMode === "dark" ? "#333" : "#dfdfdf",
            borderTopLeftRadius: 3,
            borderBottomLeftRadius: 3,
        },
        "& div:nth-of-type(2)": {
            padding: "0 15px",
        },
    }),
    joinBtn: {
        display: "flex",
        alignItems: "center",
        gap: 8,
        "& span": {
            display: "block",
        },
        "& img": {
            width: "15px",
        },
    },
    footer: {
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        textAlign: "center",
        padding: "5px 20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    footerLeft: {
        fontSize: 18,
    },
    footerLinkIcon: ({ themeMode }: StyleProps) => ({
        color: themeMode === "dark" ? "#fff" : "#000",
    }),
    topRightBar: {
        display: "flex",
        alignItems: "center",
        gap: "1rem",
        "& >span": {
            cursor: "pointer",
            marginTop: 3,
        },
    },
    appMenu: ({ themeMode }: StyleProps) => ({
        '& .ant-menu-item-active:not(.ant-menu-item-selected), & .ant-submenu-item-active:not(.ant-menu-item-selected)': {
            backgroundColor: themeMode === "light" ? '#404259 !important' : '#fff !important',
            color: themeMode === "light" ? '#fff !important' : '#404259 !important',
        },
        '& .ant-menu-submenu-selected, & .ant-menu-item-selected:not(.ant-menu-item-active)': {
            backgroundColor: themeMode === "light" ? '#404259 !important' : '#fff !important',
            color: themeMode === "light" ? '#fff !important' : '#404259 !important',
            '& .ant-menu-submenu-title': {
                color: themeMode === "light" ? '#fff !important' : '#404259 !important',
            },
            '&::after': {
                width: '100%',
                left: 0,
                insetInline: "0px !important",
                borderBotomColor: themeMode === "light" ? '#404259 !important' : '#fff !important',
            }
        },
        '& .ant-menu-overflow-item': {
            '&::after': {
                width: '100%',
                left: 0,
                insetInline: "0px !important",
                borderBotomColor: themeMode === "light" ? '#404259 !important' : '#fff !important',
            }
        },
        
    })
})

type LayoutProps = {
    children: React.ReactNode
}

const generateMenuItemsForApps = (appId: string) : MenuProps['items'] => {
    return [
        {
            label: 'Playground',
            key: `/apps/${appId}/playground`,
            icon: <RocketOutlined />,
            title: "Experiment with real data and optimize your parameters including prompts, methods, and configuration settings."
        },
        {
            label: 'Test Sets',
            key: `/apps/${appId}/testsets`,
            icon: <DatabaseOutlined />,
            title: "Create and manage testsets for evaluation purposes."
        },
        {
            label: 'Evaluation',
            key: 'SubMenu',
            icon: <CaretDownOutlined />,
            children: [
                {
                    label: 'Evaluators',
                    key: `/apps/${appId}/evaluations/new-evaluator`,
                    icon: <SlidersOutlined />,
                    title: "Select and customize evaluators such as custom code or regex evaluators."
                },
                {
                    label: 'Results',
                    key: `/apps/${appId}/evaluations/results`,
                    icon: <PlayCircleOutlined />,
                    title: "Choose your variants and evaluators to start the evaluation process."
                },
                {
                    label: 'A/B Evaluation',
                    key: `/apps/${appId}/annotations/human_a_b_testing`,
                    icon: <Image src={abTesting} alt="A/B Evaluation" style={{ width: 11, height: 11 }} />,
                    title: "A/B tests allow you to compare the performance of two different variants manually."
                },
                {
                    label: 'Single Model Eval.',
                    key: `/apps/${appId}/annotations/single_model_test`,
                    icon: <Image src={singleModel} alt="Single Model Evaluation" style={{ width: 11, height: 11 }} />,
                    title: "Single model test allows you to score the performance of a single LLM app manually."
                },
            ],
        },
        {
            label: 'Deployment',
            key: 'SubMenu2',
            icon: <CaretDownOutlined />,
            children: [
                {
                    label: 'Endpoints',
                    key: `/apps/${appId}/endpoints`,
                    icon: <CloudUploadOutlined />,
                    title: "Deploy your applications to different environments."
                },
            ],
        },
        {
            label: 'Chat with AI Skill',
            key: `/apps/${appId}/chat`,
            icon: <MessageOutlined />,
        }
    ];
}

const App: React.FC<LayoutProps> = ({ children }) => {
    const { user } = useProfileData()
    const { appTheme } = useAppTheme()
    const { currentApp, setModalInstance } = useAppsData()
    // const { currentProject } = useProjectsData()
    const capitalizedAppName = renameVariablesCapitalizeAll(currentApp?.app_name || "")
    const [footerRef, { height: footerHeight }] = useElementSize()
    const classes = useStyles({ themeMode: appTheme, footerHeight } as StyleProps)
    const router = useRouter()
    const appId = router.query.app_id as string
    const isDarkTheme = appTheme === "dark"
    const { token } = theme.useToken()
    const [modal, contextHolder] = Modal.useModal()
    const [currentRoute, setCurrentRoute] = useState('mail');

    useEffect(() => {
        if (user && isDemo()) {
            ; (window as any).intercomSettings = {
                api_base: "https://api-iam.intercom.io",
                app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
                name: user.username,
                email: user.email,
            }
                ; (function () {
                    var w: any = window
                    var ic = w.Intercom
                    if (typeof ic === "function") {
                        ic("reattach_activator")
                        ic("update", (window as any).intercomSettings)
                    } else {
                        var d = document
                        var i: any = function () {
                            i.c(arguments)
                        }
                        i.q = []
                        i.c = function (args: any) {
                            i.q.push(args)
                        }
                        w.Intercom = i
                        var l = function () {
                            var s = d.createElement("script")
                            s.type = "text/javascript"
                            s.async = true
                            s.src = `https://widget.intercom.io/widget/${process.env.NEXT_PUBLIC_INTERCOM_APP_ID}`
                            var x: any = d.getElementsByTagName("script")[0]
                            x.parentNode.insertBefore(s, x)
                        }
                        if (document.readyState === "complete") {
                            l()
                        } else if (w.attachEvent) {
                            w.attachEvent("onload", l)
                        } else {
                            w.addEventListener("load", l, false)
                        }
                    }
                })()
        } else {
            if ((window as any).Intercom) {
                ; (window as any).Intercom("shutdown")
                delete (window as any).intercomSettings
            }
        }
    }, [user])

    const isAppRoute = useMemo(
        () => router.pathname.startsWith("/apps/[app_id]"),
        [router.pathname],
    )

    useEffect(() => {
        setModalInstance(modal)
    }, [])

    useEffect(() => {
        if (typeof window === "undefined") return () => { }

        const body = document.body
        body.classList.remove("dark-mode", "light-mode")
        if (isDarkTheme) {
            body.classList.add("dark-mode")
        } else {
            body.classList.add("light-mode")
        }
    }, [appTheme])

    // wait unitl we have the app id, if its an app route
    if (isAppRoute && (!appId || !currentApp)) return null

    const onClickRoute: MenuProps['onClick'] = (e) => {
        setCurrentRoute(e.key);
        router.push(e.key);
    };

    return (
        <NoSSRWrapper>
            {typeof window === "undefined" ? null : (
                <ThemeProvider theme={{ ...token, isDark: isDarkTheme }}>
                    <Layout hasSider className={classes.layout}>
                        <Sidebar />
                        <Layout className={classes.layout}>
                            <Content className={classes.content}>
                                <Space className={classes.breadcrumbContainer}>
                                    <Breadcrumb
                                        className={classes.breadcrumb}
                                        items={[
                                            {title: <Link href="/apps">Apps</Link>},
                                            {title: capitalizedAppName},
                                        ]}
                                    />
                                </Space>
                                {router.query.app_id && (
                                    <Menu
                                        onClick={onClickRoute}
                                        selectedKeys={[currentRoute]}
                                        rootClassName={classes.appMenu}
                                        className={classes.appMenu}
                                        mode="horizontal"
                                        items={generateMenuItemsForApps(router.query.app_id as string)}
                                    />
                                )}

                                <ErrorBoundary FallbackComponent={ErrorFallback}>
                                    {children}
                                    {contextHolder}
                                </ErrorBoundary>
                            </Content>
                            <Footer ref={footerRef} className={classes.footer}>
                                <Space className={classes.footerLeft} size={10}>
                                    <div />
                                </Space>
                                <div>Copyright © {new Date().getFullYear()} | AssistBlue. Powered By Aladin.</div>
                            </Footer>
                        </Layout>
                    </Layout>
                </ThemeProvider>
            )}
        </NoSSRWrapper>
    )
}

export default App
