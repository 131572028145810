export const textMappings = {
  "App": "AI Skill",
  "Apps": "AI Skills",
  "app": "ai skill",
  "apps": "ai skills",
  "Project": "AI App",
  "project": "ai app",
  "Projects": "AI Apps",
  "projects": "AI Apps",
  "Flow": "Flow",
  "Chat": "Chat",
}