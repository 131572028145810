import React, {useEffect, useState} from "react"
import {useRouter} from "next/router"
import {
    RocketOutlined,
    AppstoreOutlined,
    SettingOutlined,
} from "@ant-design/icons"
import {Layout, Menu, Tooltip, theme} from "antd"

import Logo from "../Logo/Logo"
import Link from "next/link"
import {useAppTheme} from "../Layout/ThemeContextProvider"
import {ErrorBoundary} from "react-error-boundary"
import {createUseStyles} from "react-jss"
import AlertPopup from "../AlertPopup/AlertPopup"
import {useSession} from "@/hooks/useSession"
import {useLocalStorage} from "usehooks-ts"
import {textMappings} from "@/texts/mappings"
import {useAppsData} from "@/contexts/app.context"

type StyleProps = {
    themeMode: "system" | "dark" | "light"
    colorBgContainer: string
    colorPrimary: string
}

const {Sider} = Layout

const useStyles = createUseStyles({
    sidebar: ({colorBgContainer}: StyleProps) => ({
        background: `${colorBgContainer} !important`,
        height: "100vh",
        position: "sticky !important",
        bottom: "0px",
        top: "0px",

        "&>div:nth-of-type(2)": {
            background: `${colorBgContainer} !important`,
        },
    }),
    siderWrapper: ({themeMode}: StyleProps) => ({
        border: `0.01px solid ${themeMode === "dark" ? "#222" : "#ddd"}`,
    }),
    evaluationImg: ({themeMode}: StyleProps) => ({
        width: 20,
        height: 20,
        filter: themeMode === "dark" ? "invert(1)" : "none",
    }),
    addItem: {
        width: '100%',
    },
    sliderContainer: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: "0 10px",
        "& > div:nth-of-type(1)": {
            marginTop: "16px",
            marginBottom: "16px",
            marginRight: "10px",
            marginLeft: "10px",
            display: "flex",
            justifyContent: "center",

            '& a': {
                flex: 1,
                '& img': {
                    width: '100%',
                    objectFit: 'contain',
                }
            }
        },
        "& > div:nth-of-type(2)": {
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            flex: 1,
        },
    },
    menuContainer: ({themeMode, colorPrimary}: StyleProps) => ({
        borderRight: "0 !important",
        '& .ant-menu-item-selected': {
            position: "relative",
            backgroundColor: themeMode === "dark" ? "rgba(255, 255, 255, 0.1) !important" : "rgba(0, 0, 0, 0.1) !important",
            color: themeMode === "dark" ? "#fff" : "#000",
            fontWeight: "bold",
            "&::before": {
                content: '""',
                position: "absolute",
                left: 0,
                top: 0,
                bottom: 0,
                width: 4,
                backgroundColor: themeMode === "dark" ? "#fff" : colorPrimary,
            },
        }
    }),
    menuContainer2: {
        borderRight: "0 !important",
        '& .ant-menu-item-selected': {
            position: "relative",
            backgroundColor: "rgba(0, 0, 0, 0.1) !important",
            fontWeight: "bold",
            "&::before": {
                content: '""',
                position: "absolute",
                left: 0,
                top: 0,
                bottom: 0,
                width: 4,
                backgroundColor: "#000",
            },
        }
    },
    menuLinks: {
        width: "100%",
    },
    subMenuContainer: {
        "& .ant-menu-submenu-title": {
            paddingLeft: "16px !important",
        },
    },
})

let index = 0;

const Sidebar: React.FC = () => {
    const {appTheme} = useAppTheme()
    const {
        token: {colorBgContainer, colorPrimary},
    } = theme.useToken()
    const router = useRouter()
    const appId = router.query.app_id as string
    // const projectId = router.query.project_id as string
    const classes = useStyles({
        themeMode: appTheme,
        colorBgContainer,
        colorPrimary,
    } as StyleProps)
    const {doesSessionExist, logout} = useSession()
    const { apps } = useAppsData()

    const pathSegments = router.asPath.split("/")
    // const isAppPage = pathSegments[3] === "apps"
    const hasAppId = pathSegments[4] && pathSegments[4] !== ""
    const page_name = pathSegments[3]

    let initialSelectedKeys: string[] = []
    if (typeof page_name === "string") {
        initialSelectedKeys = [page_name]
    } else if (Array.isArray(page_name)) {
        initialSelectedKeys = page_name
    } else if (typeof page_name === "undefined") {
        initialSelectedKeys = ["apps"]
    }
    const [selectedKeys, setSelectedKeys] = useState(initialSelectedKeys)
    const [collapsed, setCollapsed] = useLocalStorage("sidebarCollapsed", false)

    const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        e.preventDefault();
    };

    useEffect(() => {
        setSelectedKeys(initialSelectedKeys)
    }, [page_name])

    const getNavigationPath = (path: string) => {
        if (path === "apps") {
            return `/apps`
        } else if (path === "chat") {
            return `/apps/${appId}/chat`
        } else {
            return `/apps/${appId}/${path}`
        }
    }

    const handleLogout = () => {
        AlertPopup({
            title: "Logout",
            message: "Are you sure you want to logout?",
            onOk: logout,
        })
    }

    const isProjectRoute = router.pathname === "/apps"
    const isChatRoute = router.pathname === "/apps/[app_id]/chat"
    const isSettingsRoute = router.pathname === "/settings"

    return (
        <div className={classes.siderWrapper}>
            <Sider
                theme={appTheme}
                className={classes.sidebar}
                width={225}
                collapsible
                collapsed={collapsed}
                onCollapse={(value) => setCollapsed(value)}
            >
                <div className={classes.sliderContainer}>
                    <div>
                        <Link data-cy="project-management-link" href={getNavigationPath("apps")}>
                            <Logo isOnlyIconLogo={collapsed} style={{ objectFit: 'contain' }} />
                        </Link>
                    </div>
                    <ErrorBoundary fallback={<div />}>
                        <div>
                            <Menu
                                mode="vertical"
                                selectedKeys={initialSelectedKeys}
                                className={classes.menuContainer}
                            >
                                <Tooltip
                                    key="apps"
                                    placement="right"
                                    title={
                                        !collapsed
                                            ? `Create new applications or switch between your existing ${textMappings.apps}.`
                                            : `${textMappings.App} Management`
                                    }
                                >
                                    <Menu.Item
                                        icon={<AppstoreOutlined />}
                                        className={isProjectRoute ? "ant-menu-item-selected" : ""}
                                    >
                                        <Link
                                            data-cy="project-management-link"
                                            href={getNavigationPath("apps")}
                                            className={classes.menuLinks}
                                        >
                                            {collapsed
                                                ? `Create new applications or switch between your existing ${textMappings.apps}.`
                                                : `${textMappings.App} Management`}
                                        </Link>
                                    </Menu.Item>
                                </Tooltip>
                                {appId && apps && apps.length > 0 && (
                                        <Menu.ItemGroup
                                            title={!collapsed && `${textMappings.Apps}`}
                                            className={classes.subMenuContainer}
                                        >
                                            {apps.map((app) => (
                                                <Tooltip
                                                    placement="right"
                                                    title={
                                                        !collapsed
                                                            ? `View ${app.app_name} details.`
                                                            : ""
                                                    }
                                                    key={`playgroundInSidebar-${app.app_id}`}
                                                >
                                                    <Menu.Item
                                                        key={app.app_id}
                                                        icon={<RocketOutlined />}
                                                        className={app.app_id === router.query.app_id ? "ant-menu-item-selected" : ""}
                                                    >
                                                        <Link
                                                            data-cy={`app-${app.app_id}-link`}
                                                            href={`/apps/${app.app_id}/playground`}
                                                            className={classes.menuLinks}
                                                        >
                                                            {collapsed ? app.app_name : app.app_name}
                                                        </Link>
                                                    </Menu.Item>
                                                </Tooltip>
                                            ))}
                                        </Menu.ItemGroup>
                                    )}
                            </Menu>

                            <Menu
                                mode="vertical"
                                className={classes.menuContainer2}
                                selectedKeys={selectedKeys}
                            >
                                {doesSessionExist && (
                                    <Menu.Item
                                        key="settings"
                                        icon={<SettingOutlined />}
                                        className={isSettingsRoute ? "ant-menu-item-selected" : ""}
                                    >
                                        <Link data-cy="settings-link" href="/settings">
                                            Settings
                                        </Link>
                                    </Menu.Item>
                                )}
                            </Menu>
                        </div>
                    </ErrorBoundary>
                </div>
            </Sider>
        </div>
    )
}

export default Sidebar
