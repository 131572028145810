import {useEffect} from "react"
import type {AppProps} from "next/app"
import {useRouter} from "next/router"

import posthog from "posthog-js"
import {PostHogProvider} from "posthog-js/react"
import { Toaster } from 'sonner';
import { GlobalStyles } from '@mui/material';
import { makeTheme } from '@/theme';
import { ThemeProvider } from '@mui/material/styles';
import { RecoilRoot } from 'recoil';
import { i18nSetupLocalization } from '@/i18n';

import "@/styles/globals.css"
import Layout from "@/components/Layout/Layout"
import ThemeContextProvider from "@/components/Layout/ThemeContextProvider"
import AppContextProvider from "@/contexts/app.context"
import ProfileContextProvider from "@/contexts/profile.context"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"

i18nSetupLocalization();

// Initialize the Posthog client
if (typeof window !== "undefined") {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_API_KEY!, {
        api_host: "https://app.posthog.com",
        // Enable debug mode in development
        loaded: (posthog) => {
            if (process.env.NODE_ENV === "development") posthog.debug()
        },
        capture_pageview: false,
        persistence: "localStorage+cookie",
    })
}

type Primary = {
    dark?: string;
    light?: string;
    main?: string;
};

type ThemOverride = {
    primary?: Primary;
    background?: string;
    paper?: string;
};

declare global {
    interface Window {
        theme?: {
            light?: ThemOverride;
            dark?: ThemOverride;
        };
    }
}

export default function App({Component, pageProps}: AppProps) {
    const router = useRouter()
    const fontFamily = "'IBM Plex Sans', sans-serif";
    const theme = makeTheme("light", fontFamily);

    useEffect(() => {
        const handleRouteChange = () =>
            posthog.capture("$pageview", {$current_url: window.location.href})
        router.events.on("routeChangeComplete", handleRouteChange)

        return () => {
            router.events.off("routeChangeComplete", handleRouteChange)
        }
    }, [])
    return (
        <PostHogProvider client={posthog}>
            <RecoilRoot>
                <ThemeProvider theme={theme}>
                    <GlobalStyles
                        styles={{
                            body: { backgroundColor: theme.palette.background.default }
                        }}
                    />
                    <Toaster
                        className="toast"
                        position="top-right"
                        toastOptions={{
                            style: {
                                fontFamily: theme.typography.fontFamily,
                                background: theme.palette.background.paper,
                                border: `1px solid ${theme.palette.divider}`,
                                color: theme.palette.text.primary
                            }
                        }}
                    />
                    <ThemeContextProvider>
                        <ProfileContextProvider>
                            <AppContextProvider>
                                <Layout>
                                    <Component {...pageProps} />
                                </Layout>
                            </AppContextProvider>
                        </ProfileContextProvider>
                    </ThemeContextProvider>
                </ThemeProvider>
            </RecoilRoot>
        </PostHogProvider>
    )
}
